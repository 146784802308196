var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"maincontent"},[_c('div',{staticClass:"measurements-buttons mobile"},[_c('a',{class:{
        button_green: 'data' == _vm.type,
        button_darkgrey: 'data' != _vm.type,
        button: true,
      },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.type = 'data'}}},[_vm._v("Measurements")]),_c('a',{class:{
        button_green: 'chart' == _vm.type,
        button_darkgrey: 'chart' != _vm.type,
        button: true,
      },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.type = 'chart'}}},[_vm._v("Progress")])]),_vm._m(0),_c('div',{staticClass:"measurements-buttons desktop"},[_c('a',{class:{
        button_green: 'data' == _vm.type,
        button_darkgrey: 'data' != _vm.type,
        button: true,
      },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.type = 'data'}}},[_vm._v("Measurements")]),_c('a',{class:{
        button_green: 'chart' == _vm.type,
        button_darkgrey: 'chart' != _vm.type,
        button: true,
      },attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();_vm.type = 'chart'}}},[_vm._v("Progress")])]),(_vm.type == 'chart')?_c('MeasurementChart'):_c('MeasurementData')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mainh1"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"mainh1_flex"},[_c('h1',[_vm._v("Your Measurements and Progress")])])])])}]

export { render, staticRenderFns }