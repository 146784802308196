<template>
  <div class="measurements-value">
    <div v-if="item.display" class="measurements-value__title">
      {{ "fat" == section ? "Body Fat %" : item.title }}
      <span
        v-if="!item.icon"
        class="tip"
        data-image="imgbig-test.png"
        @click="changeImage"
        >?</span
      >
    </div>
    <label>
      <div class="measurements-value_input">
        <div class="measurements-value__input">
          <input type="number" v-model="item.value" min="1" max="999" />

          <span class="inc" @click="increase"></span>
          <span class="dec" @click="decrease"></span>
        </div>
      </div>
      <div class="measurements-value__img">
        <img
          v-if="!item.icon"
          :src="require(`@/assets/img/body-parts/${section.toLowerCase()}.svg`)"
        />
        <i v-else :class="iconClass(section.toLowerCase())"></i>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: "MeasurementItem",
  props: ["item", "section"],
  methods: {
    increase() {
      this.item.value = (parseFloat(this.item.value) + 1).toFixed(1);
      if (
        "body fat" == this.item.title.toLowerCase() &&
        this.item.value > 100
      ) {
        this.item.value = parseFloat(100).toFixed(1);
      }
    },
    decrease() {
      this.item.value = (parseFloat(this.item.value) - 1).toFixed(1);
      if (this.item.value < 0) {
        this.item.value = parseFloat(0).toFixed(1);
      }
    },
    changeImage() {
      this.$emit("helpIconClicked", this.section);
    },
    iconClass(icon) {
      if ("fat" == icon) {
        icon = "bodyfat";
      }
      if ("height" == icon) {
        icon = "tape";
      }
      let c = `atuicons-${icon}`;
      let cls = { atuicons: true };
      cls[c] = true;
      return cls;
    },
  },
};
</script>