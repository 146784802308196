<template>
  <main class="maincontent">
    <div class="measurements-buttons mobile">
      <a
        :class="{
          button_green: 'data' == type,
          button_darkgrey: 'data' != type,
          button: true,
        }"
        href="#"
        @click.prevent="type = 'data'"
        >Measurements</a
      >
      <a
        :class="{
          button_green: 'chart' == type,
          button_darkgrey: 'chart' != type,
          button: true,
        }"
        href="#"
        @click.prevent="type = 'chart'"
        >Progress</a
      >
    </div>
    <div class="mainh1">
      <div class="wrapper">
        <div class="mainh1_flex">
          <h1>Your Measurements and Progress</h1>
        </div>
      </div>
    </div>
    <div class="measurements-buttons desktop">
      <a
        :class="{
          button_green: 'data' == type,
          button_darkgrey: 'data' != type,
          button: true,
        }"
        href="#"
        @click.prevent="type = 'data'"
        >Measurements</a
      >
      <a
        :class="{
          button_green: 'chart' == type,
          button_darkgrey: 'chart' != type,
          button: true,
        }"
        href="#"
        @click.prevent="type = 'chart'"
        >Progress</a
      >
    </div>
    <MeasurementChart v-if="type == 'chart'"></MeasurementChart>
    <MeasurementData v-else></MeasurementData>
  </main>
</template>

<script>
import { MeasurementChart } from "./components";
import { MeasurementData } from "./components";

export default {
  name: "Measurement",
  components: { MeasurementChart, MeasurementData },
  data() {
    return {
      type: "data",
    };
  },
};
</script>

<style scoped>
@import "~@/assets/css/profile.css";
@import "~@/assets/css/bio.css";
@import "~@/assets/css/biofix.css";
</style>

