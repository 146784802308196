<template>
  <Fragment>
    <section class="measurements">
      <div class="measurements-head">
        <div class="wrapper">
          <div class="measurements_head">
            <div class="title_small">Measurements</div>
            <div class="measurements-head__unit">
              <label
                ><input
                  type="radio"
                  value="INCH"
                  v-model="form.unit.main"
                /><span>in</span></label
              ><label
                ><input
                  type="radio"
                  value="CENTIMETER"
                  v-model="form.unit.main"
                /><span>cm</span></label
              >
            </div>
          </div>
        </div>
      </div>
      <div class="measurements-grid">
        <div class="measurements-values">
          <div class="wrapper">
            <div class="measurements_values">
              <div class="measurements-values__row">
                <div class="measurements-values__col">
                  <MeasurementItem
                    v-for="leftKey in items.left"
                    :key="leftKey"
                    :item="form.content[leftKey]"
                    :section="leftKey"
                    @helpIconClicked="onHelpIconClicked"
                  ></MeasurementItem>
                </div>
                <div class="measurements-values__image">
                  <img :src="bodyShapeImageForMeasurement" />
                </div>
                <div class="measurements-values__col">
                  <MeasurementItem
                    v-for="rightKey in items.right"
                    :key="rightKey"
                    :item="form.content[rightKey]"
                    :section="rightKey"
                    @helpIconClicked="onHelpIconClicked"
                  ></MeasurementItem>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="measurements-bmi">
          <div class="measurements-fat">
            <div class="measurements_head">
              <div class="title_small">Fat and BMI</div>
            </div>
            <MeasurementItem
              v-for="otherKey in items.other"
              :key="otherKey"
              :item="form.content[otherKey]"
              :section="otherKey"
            ></MeasurementItem>
          </div>
          <div class="measurements-weight">
            <div class="measurements_head">
              <div class="title_small">Weight</div>
              <div class="measurements-head__unit">
                <label
                  ><input
                    type="radio"
                    v-model="form.unit.weight"
                    value="POUND"
                  /><span>lbs</span></label
                ><label
                  ><input
                    type="radio"
                    v-model="form.unit.weight"
                    value="KILOGRAM"
                  /><span>kg</span></label
                >
              </div>
            </div>
            <MeasurementItem
              :item="form.content.weight"
              section="weight"
            ></MeasurementItem>
          </div>
          <div class="measurements-height">
            <div class="measurements_head">
              <div class="title_small">Height</div>
              <div class="measurements-head__unit">
                <label
                  ><input
                    type="radio"
                    v-model="form.unit.height"
                    value="FEET"
                  /><span>ft, in</span></label
                ><label
                  ><input
                    type="radio"
                    v-model="form.unit.height"
                    value="CENTIMETER"
                  /><span>cm</span></label
                >
              </div>
            </div>
            <MeasurementItem
              :item="form.content.height"
              section="height"
            ></MeasurementItem>
          </div>
        </div>
        <div class="measurements__save">
          <SaveButton
            :isLoading="isLoading"
            text="Save Measurements"
            @click.native="save"
          ></SaveButton>
        </div>
      </div>
    </section>
  </Fragment>
</template>

<script>
import { convert } from "@/api/unit-converter";
import { Fragment } from "vue-fragment";
import MeasurementItem from "./MeasurementData/Item.vue";
import { fetchMeasurementData } from "@/api/body-measurement";
import { save } from "@/api/body-measurement";
import swal from "sweetalert";
import SaveButton from "@/components/SaveButton";
import NProgress from "nprogress";

export default {
  name: "MeasurementData",
  components: { Fragment, MeasurementItem, SaveButton },
  data() {
    return {
      isLoading: false,
      items: {
        left: ["chest", "neck", "hip", "shoulder", "lowerAbs", "upperAbs"],
        right: [
          "leftThigh",
          "rightThigh",
          "leftCalf",
          "rightCalf",
          "leftBicep",
          "rightBicep",
        ],
        other: ["bmi", "fat"],
      },
      bodyShapeImageForMeasurement: null,
      form: {
        unit: {
          main: "CENTIMETER",
          height: "CENTIMETER",
          weight: "KILOGRAM",
        },
        content: {
          bmi: {
            value: "",
            title: "BMI",
            display: true,
            icon: true,
            unit: null,
          },
          fat: {
            value: "",
            title: "Body Fat",
            display: true,
            icon: true,
            unit: null,
          },
          chest: {
            value: "",
            title: "Chest",
            display: true,
            icon: false,
            unit: null,
          },
          neck: {
            value: "",
            title: "Neck",
            display: true,
            icon: false,
            unit: null,
          },
          hip: {
            value: "",
            title: "Hips",
            display: true,
            icon: false,
            unit: null,
          },
          shoulder: {
            value: "",
            title: "Shoulders-Around",
            display: true,
            icon: false,
            unit: null,
          },
          lowerAbs: {
            value: "",
            title: "Lower Abs",
            display: true,
            icon: false,
            unit: null,
          },
          upperAbs: {
            value: "",
            title: "Upper Abs",
            display: true,
            icon: false,
            unit: null,
          },
          leftThigh: {
            value: "",
            title: "Left Thigh",
            display: true,
            icon: false,
            unit: null,
          },
          rightThigh: {
            value: "",
            title: "Right Thigh",
            display: true,
            icon: false,
            unit: null,
          },
          leftCalf: {
            value: "",
            title: "Left Calf",
            display: true,
            icon: false,
            unit: null,
          },
          rightCalf: {
            value: "",
            title: "Right Calf",
            display: true,
            icon: false,
            unit: null,
          },
          leftBicep: {
            value: "",
            title: "Left Bicep",
            display: true,
            icon: false,
            unit: null,
          },
          rightBicep: {
            value: "",
            title: "Right Bicep",
            display: true,
            icon: false,
            unit: null,
          },
          height: {
            value: "",
            title: "Height",
            display: false,
            icon: true,
            unit: null,
          },
          weight: {
            value: "",
            title: "Weight",
            display: false,
            icon: true,
            unit: null,
          },
        },
      },
    };
  },
  created() {
    this.bodyShapeImageForMeasurement = this.profile.bodyType.image;
    this.loadData();
  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
  },
  watch: {
    "form.unit.main": {
      handler: function (after, before) {
        let keys = [...this.items.left, ...this.items.right];
        this.updateValueAfterUnitChange(keys, before, after);
      },
      deep: true,
    },
    "form.unit.height": {
      handler: function (after, before) {
        let keys = ["height"];
        this.updateValueAfterUnitChange(keys, before, after);
      },
      deep: true,
    },
    "form.unit.weight": {
      handler: function (after, before) {
        let keys = ["weight"];
        this.updateValueAfterUnitChange(keys, before, after);
      },
      deep: true,
    },
  },
  methods: {
    updateValueAfterUnitChange(keys, from, to) {
      let items = [];
      keys.forEach((key) => {
        items.push({
          value: this.form.content[key].value,
          unit: from,
          convert: to,
          key,
        });
      });
      NProgress.start();
      convert({ items })
        .then(({ data }) => {
          keys.forEach((key) => {
            this.form.content[key].value = data[key].value;
            this.form.content[key].unit = data[key].unit;
          });
        })
        .catch((error) => {
          console.log("error while unit converting", error);
          swal(
            "Failed Unit Conversion!",
            "Please reload the page or try again later!",
            "error"
          );
        })
        .finally(() => {
          NProgress.done();
        });
    },
    onHelpIconClicked(type) {
      let profile = this.profile;
      type = type.toLowerCase();
      let gender = profile.gender.toLowerCase();
      let shape = profile.bodyType.officialName.toLowerCase();
      this.bodyShapeImageForMeasurement = require(`@/assets/img/body-shape/${type}/${gender}/${type}-${shape}.svg`);
    },
    loadData() {
      this.isLoading = true;
      NProgress.start();
      fetchMeasurementData()
        .then(({ data }) => {
          this.form.content = data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
          NProgress.done();
        });
    },
    save() {
      this.isLoading = true;
      save({ content: this.form.content })
        .then(() => {
          swal(
            "Measurements Updated!",
            "Your measurements have been updated successfully",
            "success"
          );
        })
        .catch((error) => {
          console.log(error.message);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>