var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"bioprogress"},[_c('div',{staticClass:"bioprogress-filters"},[_c('div',{staticClass:"bioprogress-filter"},[_vm._m(0),_c('CustomSelect',{attrs:{"options":_vm.options.types},on:{"input":_vm.onChangeType}})],1),_c('div',{staticClass:"bioprogress-filter"},[_vm._m(1),_c('CustomSelect',{attrs:{"options":_vm.options.dates},on:{"input":_vm.onChangeDate}})],1)]),(!_vm.isLoading)?_c('div',{staticClass:"bioprogress-stats"},[(_vm.summary)?_c('Fragment',[_c('div',{staticClass:"bioprogress-stats__stat"},[_c('div',{staticClass:"bioprogress-stats__title"},[_vm._v("Start")]),_c('div',{staticClass:"bioprogress-stats__value"},[_vm._v(" "+_vm._s(_vm.summary.start)+" ")])]),_c('div',{staticClass:"bioprogress-stats__stat"},[_c('div',{staticClass:"bioprogress-stats__title"},[_vm._v("Current")]),_c('div',{staticClass:"bioprogress-stats__value"},[_vm._v(" "+_vm._s(_vm.summary.current)+" "),_c('i',{class:{
              atuicons: true,
              'atuicons-arrow-down-short': _vm.summary.change.percentage < 0,
              'atuicons-arrow-up-short': _vm.summary.change.percentage > 0,
            }})])]),_c('div',{staticClass:"bioprogress-stats__stat"},[_c('div',{staticClass:"bioprogress-stats__title"},[_vm._v(" Change ("+_vm._s(_vm.summary.change.percentage)+"%) ")]),_c('div',{staticClass:"bioprogress-stats__value"},[_vm._v(" "+_vm._s(_vm.summary.change.value)),_c('i',{class:{
              atuicons: true,
              'atuicons-arrow-down-short': _vm.summary.change.percentage < 0,
              'atuicons-arrow-up-short': _vm.summary.change.percentage > 0,
            }})])])]):_vm._e()],1):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"bioprogress-grid"},[_c('div',{staticClass:"measurements-head result"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"measurements_head"},[_c('div',{staticClass:"title_small"},[_vm._v(" Your Progress: "+_vm._s(_vm.selected.type.key)+" ")])])])]),(_vm.chartdata)?_c('div',{staticClass:"measurements-grapth"},[_c('LineChart',{attrs:{"chart-data":_vm.chartdata}})],1):_c('div',[_vm._v("No content found!")]),(_vm.entries.length)?_c('div',{staticClass:"measurements-head entries"},[_vm._m(2)]):_vm._e(),_c('div',{staticClass:"measurements-entries"},_vm._l((_vm.entries),function(entry){return _c('div',{key:entry.id,staticClass:"measurements-entries__entrie"},[_c('div',{staticClass:"measurements-entrie"},[_c('div',{staticClass:"measurements-entries__date"},[_vm._v(" "+_vm._s(entry.creationDateString)+" ")]),_c('div',{staticClass:"measurements-entries__value"},[_vm._v(" "+_vm._s(entry.value)+_vm._s(_vm.getUnitString(_vm.defaultUnit))+" ")])]),_c('div',{class:{
            'measurements-entries__status': true,
            red: entry.status == 'down',
            green: entry.status == 'up',
          }},[_c('i',{class:{
              atuicons: true,
              'atuicons-arrow-down': entry.status == 'down',
              'atuicons-arrow-up': entry.status == 'up',
            }})])])}),0)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bioprogress-filter__icon"},[_c('i',{staticClass:"atuicons atuicons-height"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bioprogress-filter__icon"},[_c('i',{staticClass:"atuicons atuicons-calendar"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"measurements_head"},[_c('div',{staticClass:"title_small"},[_vm._v("Entries")])])])}]

export { render, staticRenderFns }