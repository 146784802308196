<template>
  <section class="bioprogress">
    <div class="bioprogress-filters">
      <div class="bioprogress-filter">
        <div class="bioprogress-filter__icon">
          <i class="atuicons atuicons-height"></i>
        </div>
        <CustomSelect :options="options.types" @input="onChangeType" />
      </div>
      <div class="bioprogress-filter">
        <div class="bioprogress-filter__icon">
          <i class="atuicons atuicons-calendar"></i>
        </div>
        <CustomSelect :options="options.dates" @input="onChangeDate" />
      </div>
    </div>
    <div class="bioprogress-stats" v-if="!isLoading">
      <Fragment v-if="summary">
        <div class="bioprogress-stats__stat">
          <div class="bioprogress-stats__title">Start</div>
          <div class="bioprogress-stats__value">
            {{ summary.start }}
          </div>
        </div>
        <div class="bioprogress-stats__stat">
          <div class="bioprogress-stats__title">Current</div>
          <div class="bioprogress-stats__value">
            {{ summary.current }}
            <i
              :class="{
                atuicons: true,
                'atuicons-arrow-down-short': summary.change.percentage < 0,
                'atuicons-arrow-up-short': summary.change.percentage > 0,
              }"
            ></i>
          </div>
        </div>
        <div class="bioprogress-stats__stat">
          <div class="bioprogress-stats__title">
            Change ({{ summary.change.percentage }}%)
          </div>
          <div class="bioprogress-stats__value">
            {{ summary.change.value
            }}<i
              :class="{
                atuicons: true,
                'atuicons-arrow-down-short': summary.change.percentage < 0,
                'atuicons-arrow-up-short': summary.change.percentage > 0,
              }"
            ></i>
          </div>
        </div>
      </Fragment>
    </div>
    <div class="bioprogress-grid" v-if="!isLoading">
      <div class="measurements-head result">
        <div class="wrapper">
          <div class="measurements_head">
            <div class="title_small">
              Your Progress: {{ selected.type.key }}
            </div>
          </div>
        </div>
      </div>
      <div class="measurements-grapth" v-if="chartdata">
        <LineChart :chart-data="chartdata"></LineChart>
      </div>
      <div v-else>No content found!</div>
      <div class="measurements-head entries" v-if="entries.length">
        <div class="wrapper">
          <div class="measurements_head">
            <div class="title_small">Entries</div>
          </div>
        </div>
      </div>
      <div class="measurements-entries">
        <div
          class="measurements-entries__entrie"
          v-for="entry in entries"
          :key="entry.id"
        >
          <div class="measurements-entrie">
            <div class="measurements-entries__date">
              {{ entry.creationDateString }}
            </div>
            <div class="measurements-entries__value">
              {{ entry.value }}{{ getUnitString(defaultUnit) }}
            </div>
          </div>
          <div
            :class="{
              'measurements-entries__status': true,
              red: entry.status == 'down',
              green: entry.status == 'up',
            }"
          >
            <i
              :class="{
                atuicons: true,
                'atuicons-arrow-down': entry.status == 'down',
                'atuicons-arrow-up': entry.status == 'up',
              }"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import CustomSelect from "@/components/CustomSelect";
import LineChart from "./LineChart.vue";
import { fetchChartData } from "@/api/body-measurement";
import NProgress from "nprogress";
import { Fragment } from "vue-fragment";

export default {
  name: "MeasurementChart",
  components: { CustomSelect, LineChart, Fragment },
  data() {
    return {
      isLoading: false,
      chartdata: null,
      summary: null,
      entries: [],
      defaultUnit: null,
      selected: {
        date: { key: "1 Week", value: "-1week" },
        type: { key: "Chest", value: "chest" },
      },
      options: {
        types: [
          { key: "Chest", value: "chest" },
          { key: "Neck", value: "neck" },
          { key: "Shoulders-Around", value: "shoulder" },
          { key: "Lower Abs", value: "lowerAbs" },
          { key: "Upper Abs", value: "upperAbs" },
          { key: "Left Thigh", value: "leftThigh" },
          { key: "Right Thigh", value: "rightThigh" },
          { key: "Left Calf", value: "leftCalf" },
          { key: "Right Calf", value: "rightCalf" },
          { key: "Left Bicep", value: "leftBicep" },
          { key: "Right Bicep", value: "rightBicep" },
          { key: "Height", value: "height" },
          { key: "Body Fat %", value: "fat" },
          { key: "BMI", value: "bmi" },
          { key: "Weight", value: "weight" },
          { key: "Hip", value: "hip" },
        ],
        dates: [
          { key: "1 Week", value: "-1week" },
          { key: "1 Month", value: "-1month" },
          { key: "3 Months", value: "-3month" },
          { key: "6 Months", value: "-6month" },
          { key: "12 Months", value: "-12month" },
          { key: "All Time", value: "all" },
        ],
      },
    };
  },

  created() {
    this.fillChartData();
  },

  computed: {
    profile() {
      return this.$store.getters.profile;
    },
  },

  methods: {
    getUnitString(unit) {
      let brick = this.selected.type.value;

      if (brick == "bmi") {
        return "";
      }
      if (brick == "fat") {
        return " (%)";
      }

      return ` (${unit})`;
    },

    onChangeType(type) {
      this.selected.type = type;
      this.fillChartData();
    },

    onChangeDate(date) {
      this.selected.date = date;
      this.fillChartData();
    },

    fillChartData() {
      NProgress.start();
      this.isLoading = true;
      fetchChartData(this.selected.type.value, this.selected.date.value)
        .then(({ data }) => {
          if (typeof data === "object") {
            this.chartdata = data.chartdata;
            this.summary = data.summary;
            this.entries = data.entries;
            this.defaultUnit = data.defaultUnit;
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
          NProgress.done();
        });
    },
  },
};
</script>