import request from '@/utils/request'

export function fetchChartData(column, duration) {
    return request({
        url: `/api/body-measurement/chart/${column}/${duration}`,
        method: 'get',
    })
}

export function fetchMeasurementData() {
    return request({
        url: `/api/body-measurement/view`,
        method: 'get',
    })
}


export function save(data) {
    return request({
        url: `/api/body-measurement/save`,
        method: 'post',
        data
    })
}

